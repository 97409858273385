@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;600;800&display=swap');
.bg-dark-element{
    background-color: hsl(209, 23%, 22%);
    color: white;
}
.bg-dark-single{
    background-color: hsl(207, 26%, 17%);
}
.dark-mode-bg{
    background-color: hsl(207, 26%, 17%);
}
.custom-font{
    font-family: 'Nunito Sans', sans-serif;;
}
.custom-font h5{
    font-weight: 800;
} 
.custom-font p{
    font-weight: 300;
}
.custom-font p span{
    font-weight: 600;
}
.custom-input{
    /* padding: 1rem 10rem 1rem 2.5rem; */
    text-align: start;
    font-weight: 600;
    border: none;
    border-radius: .8rem;
}
.custom-light{
    color: hsl(0, 0%, 52%);
    background-color: white;
}
.custom-light:hover{
    background-color: white;
    color: hsl(202, 3%, 55%);
}
.custom-dark{
    color: hsl(0, 0%, 52%);
}
.custom-dark:hover{
    color: hsl(0, 0%, 52%);;
}
.custom-dd{
    padding: 1rem 2.5rem 1rem 2.5rem;
    border: none;
    border-radius: .8rem;
    font-weight: 600;
}

@media (min-width: 768px){
    .card{
        margin: 5px !important;
    }
  }